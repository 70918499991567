import { IBannerComponentData } from "components/Banner"
import {
  NFButtonDefaultData,
  parseNFButton,
} from "gatsby-theme-nurofen/src/components/common/NFButton/parsers"
import { IUmbracoBannersPageData } from "./model"

export const bannerDefaultData: IBannerComponentData = {
  title: "",
  titleRte: undefined,
  titleColor: undefined,
  text: undefined,
  logo: undefined,
  logoAlt: "",
  img: undefined,
  imageAlt: "",
  bgBannerColor: undefined,
  breadcrumbs: [],
  pageBreaker: false,
  type: undefined,
  btn: {
    ...NFButtonDefaultData,
  },
  ariaLabel: "",
  hideTextOnMobile: false,
  hideImgOnMobile: false,
  doesTextUnderImageOnMobile: true,
}

export const parseBanner = (
  param: PageBanner.IUmbracoPageBannerMainProperties,
  pageData?: IUmbracoBannersPageData
): IBannerComponentData => {
  const result: IBannerComponentData = {
    disclaimer: param?.disclaimer,
    isComponentExist: param?.pageBannerIsBannerComponentExist === "1",
    title: param?.pageBannerTitle || bannerDefaultData.title,
    titleRte: param?.pageBannerTitleRte,
    titleColor: param?.pageBannerTitleColor
      ? param?.pageBannerTitleColor[0]?.properties.colorPicker?.label ||
        undefined
      : bannerDefaultData.titleColor,
    text: param?.pageBannerText || bannerDefaultData.text,
    logo: param?.pageBannerLogo || bannerDefaultData.logo,
    logoAlt: param?.pageBannerLogoAlt || bannerDefaultData.logoAlt,
    img: param?.pageBannerImage ? param.pageBannerImage : bannerDefaultData.img,
    backgroundImage: param?.backgroundImage ? param.backgroundImage : null,
    imageAlt: param?.pageBannerImageAlt || bannerDefaultData.imageAlt,
    bgBannerColor: param?.pageBannerBannerBgColor
      ? param?.pageBannerBannerBgColor[0]?.properties.colorPicker?.label ||
        undefined
      : bannerDefaultData.bgBannerColor,
    breadcrumbs: pageData?.breadCrumbs || bannerDefaultData.breadcrumbs,
    pageBreaker: param?.pageBannerPageBreaker === "1",
    type:
      (param?.pageBannerType?.[0] as IBannerComponentData["type"]) ||
      bannerDefaultData.type,
    theme: param?.pageBannerTheme?.[0] as IBannerComponentData["theme"],
    btn: param?.pageBannerButtonData?.length
      ? { ...parseNFButton(param?.pageBannerButtonData[0].properties) }
      : undefined,
    ariaLabel: param?.pageBannerAriaLabel || bannerDefaultData.ariaLabel,
    hideTextOnMobile: param?.pageBannerHideTextOnMobile === "1",
    hideImgOnMobile: param?.pageBannerHideImgOnMobile === "1",
    doesTextUnderImageOnMobile: !param?.pageBannerDoesTextBlockUnderImageOnMobile
      ? true
      : param.pageBannerDoesTextBlockUnderImageOnMobile === "1",
    isDisplaySubnavigation: param?.pageBannerDisplaySubnavigation === "1",
  }

  return result
}
